import { validation, validationRegex } from 'site-modules/shared/components/form-validation/validation';
import { asyncValidation } from 'site-modules/shared/components/form-validation/validation-async';

const getAsyncPhoneValidation = async (onlyDigitsPhone, id, options) => {
  const validatePhone = await asyncValidation.fetchPhoneValidation({ phone: onlyDigitsPhone, id }, options);
  return validatePhone.isPhoneValid;
};

/**
 * Validate Phone, must be numeric more than 10 digits, no invalid characters, no more than 7 the same digits,
 * does not start 01, does not have invalid area codes.
 * Makes call to Edmunds Phone Validation API if isAsync is true
 *
 * @param {object} field (i.e. { fieldValue: '310-309-4567', minLength: 10, isAsync:true, id:'insider' })
 * @returns {boolean} is field valid
 */
const validatePhoneNumber = async (field, options) => {
  /* clean phone all non-numeric values */
  const onlyDigitsPhone = field.fieldValue.replace(/\D/g, '');

  const isValid = onlyDigitsPhone.length
    ? validationRegex.RE_PHONE_HAS_TEN_DIGITS.test(onlyDigitsPhone) &&
      !validationRegex.RE_PHONE_SEVEN_SAME_LAST_DIGITS.test(onlyDigitsPhone) &&
      !validationRegex.RE_PHONE_STARTS_WITH_01.test(onlyDigitsPhone) &&
      !validationRegex.RE_PHONE_WRONG_AREA_CODE.test(onlyDigitsPhone) &&
      !validationRegex.RE_PHONE_WRONG_PREFIX.test(onlyDigitsPhone)
    : true;

  return isValid && field.isAsync ? getAsyncPhoneValidation(onlyDigitsPhone, field.id, options) : isValid;
};

/**
 * Validate zip code, Must be 5 digits long
 *
 * @param {object} field (i.e. { fieldValue: '90404'})
 * @returns {boolean} is field valid
 */
const validateZipCode = field =>
  field.fieldValue.length ? validationRegex.RE_ZIP_HAS_FIVE_DIGITS.test(field.fieldValue) : true;

/**
 * Checks if value is not empty and equal matchValue (or not is case of matchReverse = true)
 * @param {object} field (i.e. { fieldValue: 'pass123', minLength: '321pass', reverseFlag: 'true' })
 * @return { boolean } Is field valid
 */
const shouldMatchAndNotEmpty = field => {
  const { fieldValue: value, minLength: matchValue, reverseFlag: matchReverse } = field;
  const isMatching = matchReverse === true ? value !== matchValue : value === matchValue;
  return !!value.length && isMatching;
};

/**
 * Validate Street, must be alpha numeric, no invalid characters, starts with digit, no more than 2 the same characters
 * in sequence and no seven consonants consequently
 *
 * @param {object} field (i.e. { fieldValue: '123 big street', minLength: 1})
 * @returns {boolean} is field valid
 */
const validateStreet = field => {
  if (!field.fieldValue) return false;
  const trimmedStreet = field.fieldValue.trim();
  return (
    validation.validateMinLength(trimmedStreet, field.minLength) &&
    validationRegex.RE_ADDRESS_HAS_NO_WRONG_CHARACTERS.test(trimmedStreet) &&
    !validationRegex.RE_NAME_SEVEN_CONSONANTS_CONSEQUENTLY.test(trimmedStreet) &&
    validationRegex.RE_PHONE_STARTS_WITH_DIGIT.test(trimmedStreet) &&
    !validationRegex.RE_NAME_THREE_SAME_CHARACTERS_CONSEQUENTLY.test(trimmedStreet)
  );
};

/**
 * Validate City, no invalid characters, no more than 2 the same characters
 * in sequence and no seven consonants consequently
 *
 * @param {object} field (i.e. { fieldValue: 'Some City', minLength: 1})
 * @returns {boolean} is field valid
 */
const validateCity = field => {
  if (!field.fieldValue) return false;
  const trimmedCity = field.fieldValue.trim();
  return (
    validation.validateMinLength(trimmedCity, field.minLength) &&
    validationRegex.RE_ADDRESS_HAS_NO_WRONG_CHARACTERS.test(trimmedCity) &&
    !validationRegex.RE_NAME_SEVEN_CONSONANTS_CONSEQUENTLY.test(trimmedCity) &&
    !validationRegex.RE_NAME_THREE_SAME_CHARACTERS_CONSEQUENTLY.test(trimmedCity)
  );
};

/**
 * Validate Name, more than minLength specified
 * in sequence and no seven consonants consequently
 *
 * @param {object} field (i.e. { fieldValue: 'SomeName', minLength: 1})
 * @returns {boolean} is field valid
 */
function validateName(field) {
  const name = field.fieldValue;
  if (!name) return false;
  const trimmedName = name.trim();
  return validation.validateMinLength(trimmedName, field.minLength) && validation.validateName(trimmedName);
}

/**
 * Validate field minLength
 *
 * @param {object} field (i.e. { fieldValue: 'SomeValue', minLength: 7})
 * @returns {boolean} is field valid
 */
function validateFieldLength(field) {
  return validation.validateMinLength(field.fieldValue, field.minLength);
}

export const validationHelper = {
  validatePhoneNumber,
  validateZipCode,
  shouldMatchAndNotEmpty,
  validateStreet,
  validateCity,
  validateName,
  validateFieldLength,
};
