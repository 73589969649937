import { toArray } from 'lodash';
import { connectToModel, bindToPath } from 'client/data/luckdragon/redux/react-binding';
import { buildMakeModelYearsPath, VehicleModel } from 'client/data/models/vehicle';
import { PUB_STATES } from 'client/constants/pub-states';
import { sortByYear } from 'site-modules/shared/utils/sorting';
import { getLatestYears, getCurrentDate } from 'site-modules/shared/utils/date-utils';
import { filterPubStates } from 'site-modules/shared/utils/publication-states';
import { Select } from 'site-modules/shared/components/select/select';
import { StyledSelect } from 'site-modules/shared/components/styled-select/styled-select';

import { DEFAULT_CLASSNAMES } from './constants';

export function filterYears(
  years,
  { pubStates, showLatestYears, splitNewUsed, earliestYear, includeNextYear = true, additionalOptions }
) {
  let sortedYears = filterPubStates(toArray(years), pubStates).sort(sortByYear);

  if (showLatestYears) {
    const latestYears = getLatestYears();
    return sortedYears.filter(({ year }) => latestYears.includes(Number(year)));
  }

  if (splitNewUsed) {
    const splitYears = [];
    sortedYears.forEach(year =>
      year.pubStates.NEW_USED
        ? splitYears.push(
            { ...year, displayPubState: PUB_STATES.NEW, label: `${year.year} - New` },
            { ...year, displayPubState: PUB_STATES.USED, label: `${year.year} - Used` }
          )
        : splitYears.push({ ...year, label: year.year })
    );

    return splitYears;
  }

  if (earliestYear) {
    sortedYears = sortedYears.filter(({ year }) => year >= earliestYear);
  }

  if (!includeNextYear) {
    const nextYear = getCurrentDate().getFullYear() + 1;
    sortedYears = sortedYears.filter(({ year }) => year < nextYear);
  }

  return [...sortedYears, ...(additionalOptions || [])];
}

const stateToPropsConfig = {
  options: bindToPath(
    props => {
      const { make, model } = props;
      return make && model && buildMakeModelYearsPath({ make: make.slug, model: model.slug });
    },
    VehicleModel,
    filterYears
  ),
};

export const YearDropdown = connectToModel(Select, stateToPropsConfig);

const DEFAULT_PROPS = {
  name: 'select-year',
  toggle: 'Select Year',
  valueKey: 'year',
  labelKey: 'year',
};

export const StyledYearDropdown = connectToModel(StyledSelect, stateToPropsConfig);

StyledYearDropdown.defaultProps = {
  ...DEFAULT_PROPS,
};

YearDropdown.defaultProps = {
  ...DEFAULT_PROPS,
  className: `js-years-select ${DEFAULT_CLASSNAMES}`,
};
