import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Spinner } from 'site-modules/shared/components/spinner/spinner';

export const AppraisalLazyLoadDrawerLoadingPlaceholder = ({ backButtonSlot, creativeId, spinnerClassName }) => (
  <div data-tracking-parent={creativeId} className="appraisal-lazy-load-drawer-loading-placeholder">
    {backButtonSlot}
    <div className={spinnerClassName}>
      <Spinner className={classnames('d-block mx-auto')} size={48} thickness={5} color="primary" />
    </div>
  </div>
);

AppraisalLazyLoadDrawerLoadingPlaceholder.propTypes = {
  backButtonSlot: PropTypes.node,
  creativeId: PropTypes.string,
  spinnerClassName: PropTypes.string,
};

AppraisalLazyLoadDrawerLoadingPlaceholder.defaultProps = {
  backButtonSlot: null,
  creativeId: undefined,
  spinnerClassName: 'my-2',
};
