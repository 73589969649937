export const DEFAULT_CLASSNAMES = 'mmy-select w-100 btn btn-sm btn-secondary';

export const ALL_MAKES_OPTION = { name: 'All makes', slug: 'all' };

export const DROPDOWN_NAMES = {
  MAKE: 'select-make',
  MODEL: 'select-model',
  SUBMODEL: 'select-submodel',
  YEAR: 'select-year',
  STYLE: 'select-style',
};
