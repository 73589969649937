import { toArray, groupBy, mapValues } from 'lodash';
import { connectToModel, bindToPath } from 'client/data/luckdragon/redux/react-binding';
import { VehicleModel } from 'client/data/models/vehicle';
import { SelectComponent } from 'site-modules/shared/components/vehicle-dropdowns/select-component';
import { StyledSelect } from 'site-modules/shared/components/styled-select/styled-select';
import { sortByNameViaCollator } from 'site-modules/shared/utils/sorting';
import { filterPubStates } from 'site-modules/shared/utils/publication-states';
import { PUB_STATES } from 'client/constants/pub-states';
import { DEFAULT_CLASSNAMES } from './constants';

const POPULAR_MODELS_LABEL = 'Popular Models';
const OTHER_MODELS_LABEL = 'Other Models';

const stateToPropsConfig = {
  options: bindToPath(
    props => {
      const { make } = props;
      return make && `makes["${make.slug}"].models`;
    },
    VehicleModel,
    (submodelData, props) => filterPubStates(toArray(submodelData), props.pubStates).sort(sortByNameViaCollator)
  ),
  // Group options for used pubStates to display currently in prod models first
  optionsGrouped: bindToPath(
    props => {
      const { make, pubStates } = props;
      return pubStates.includes(PUB_STATES.USED) && make && `makes["${make.slug}"].models`;
    },
    VehicleModel,
    (submodelData, props) => {
      const filteredOptions = filterPubStates(toArray(submodelData), props.pubStates);
      const optionsGrouped = groupBy(filteredOptions, ({ pubStates }) =>
        pubStates[PUB_STATES.NEW] || pubStates[PUB_STATES.NEW_USED] ? 'popular' : 'other'
      );

      // If only one group is filled, return empty obj to use options array instead
      if (Object.keys(optionsGrouped).length < 2) return {};

      const { popular, other } = mapValues(optionsGrouped, options => options.sort(sortByNameViaCollator));

      // Hack to return popular models first
      return {
        [POPULAR_MODELS_LABEL]: popular,
        [OTHER_MODELS_LABEL]: other,
      };
    }
  ),
};

export const ModelDropdown = connectToModel(SelectComponent, stateToPropsConfig);

export const StyledModelDropdown = connectToModel(StyledSelect, stateToPropsConfig);

const DEFAULT_PROPS = {
  labelText: 'Model',
  isLabelHidden: true,
  name: 'select-model',
  toggle: 'Select Model',
  valueKey: 'name',
  labelKey: 'name',
};

ModelDropdown.defaultProps = {
  ...DEFAULT_PROPS,
  className: `js-models-select ${DEFAULT_CLASSNAMES}`,
};

StyledModelDropdown.defaultProps = {
  ...DEFAULT_PROPS,
};
