import { includes, isEmpty, find, reduce } from 'lodash';
import {
  COLORS_AND_OPTIONS,
  CONDITION_AND_MILEAGE,
  LOCATION_AND_STYLE,
  NOT_VALID_CONDITION_LIST,
  PARTNER_OFFER_CONDITIONS,
  VIN_HAS_INCORRECT_FORMAT,
} from 'client/site-modules/shared/constants/appraisal/appraisal';
import { validationHelper } from 'site-modules/shared/components/validation/validation-helper/validation-helper';
import { validation } from 'site-modules/shared/components/form-validation/validation';
import { numberWithCommas } from 'site-modules/shared/utils/string';

const currentYear = new Date().getFullYear();
const MINIMUM_MILEAGE_BY_MODEL_YEAR = 1000;

export const ZIP_CODE_INPUT = 'zipCode';
export const MAKE_INPUT = 'make';
export const MODEL_INPUT = 'model';
export const YEAR_INPUT = 'year';
export const STYLE_INPUT = 'style';
export const COLOR_INPUT = 'color';
export const TRANSMISSION_TYPE_INPUT = 'transmissionType';
export const MILEAGE_INPUT = 'mileage';
export const MILEAGE_INPUT_MAXIMUM_VALUE = 'mileageTooBigValue';
export const MILEAGE_INPUT_MINIMUM_VALUE_BY_MODEL_YEAR = 'mileageMinimumByModelYear';
export const MILEAGE_MAX_VALUE = 300000;
export const CONDITION_INPUT = 'condition';
export const VIN_INPUT = 'vin';
export const EPO_VIN_INPUT_CONTAINER = 'epo-vin';
export const PARTNER_OFFER_MILEAGE_INPUT = 'partnerOfferMileage';
export const CONDITIONS_QUESTIONS_SECTION_INPUT = 'conditionsQuestionsSection';
export const EXTERIOR_COLOR = 'exteriorColor';

export const ERROR_MAP = new Map([
  [ZIP_CODE_INPUT, 'Please enter your zip code'],
  [MAKE_INPUT, 'Please select a make'],
  [MODEL_INPUT, 'Please select a model'],
  [YEAR_INPUT, 'Please select a year'],
  [STYLE_INPUT, 'Please select a style'],
  [MILEAGE_INPUT, 'Please enter vehicle mileage'],
  [MILEAGE_INPUT_MAXIMUM_VALUE, `Mileage cannot be greater than ${numberWithCommas(MILEAGE_MAX_VALUE)}`],
  [MILEAGE_INPUT_MINIMUM_VALUE_BY_MODEL_YEAR, 'Please enter a valid mileage'],
  [CONDITION_INPUT, 'Please enter vehicle condition'],
  [VIN_INPUT, VIN_HAS_INCORRECT_FORMAT],
  [EPO_VIN_INPUT_CONTAINER, VIN_HAS_INCORRECT_FORMAT],
  [PARTNER_OFFER_MILEAGE_INPUT, 'Please enter vehicle mileage'],
  [
    CONDITIONS_QUESTIONS_SECTION_INPUT,
    {
      singleQuestionErrorMessage: 'Please answer this question',
      multipleQuestionsErrorMessage: 'Please answer all of these questions',
    },
  ],
  [TRANSMISSION_TYPE_INPUT, 'Please select a transmission type'],
  [EXTERIOR_COLOR, 'Please select an exterior color'],
]);

const DAMAGED_CONDITION_ERROR = 'Please enter a non-damaged vehicle condition';

export function isValueSet(fieldValue) {
  return !!fieldValue;
}

export function isConditionNotDamaged(fieldValue) {
  return !includes(NOT_VALID_CONDITION_LIST, fieldValue);
}

export function isConditionValid(fieldValue) {
  return !!fieldValue;
}

/**
 * Round mileage value
 * @param value
 * @returns {string}
 */
export function transformMileage(value) {
  const niceValue = value.replace(/,/gm, '');
  const transformedValue = parseInt(niceValue, 10) || 0;
  return `${transformedValue}`;
}

/**
 * Replace O,o,Q,q,I,i to similar numbers
 * @param value
 * @returns {string}
 */
export function transformVin(value) {
  return value
    .replace(/o/gi, '0')
    .replace(/i/gi, '1')
    .replace(/q/gi, '9')
    .toUpperCase();
}

/**
 * Validate if mileage value is greater than 0
 * @param {String} value
 * @returns {Boolean}
 */
export function validateMileage(value) {
  return parseInt(transformMileage(value), 10) > 0;
}

/**
 * Validate if mileage value is less or equal than max allowed value
 * @param {String} value
 * @returns {Boolean}
 */
export function validateMaxMileage(value) {
  return parseInt(transformMileage(value), 10) <= MILEAGE_MAX_VALUE;
}

/**
 * Validate if mileage value is less or equal than max allowed value
 * @param {String} value
 * @param {Object} additionalParameters - extra parameters that is needed to validate
 *  - @param {Number} styleYear - vehicle style year
 * @returns {Boolean}
 */
export function validateMileageWithModelYear(value, { styleYear }) {
  const transformedValue = parseInt(transformMileage(value), 10);
  return styleYear <= currentYear - 2 ? transformedValue >= MINIMUM_MILEAGE_BY_MODEL_YEAR : true;
}

/**
 * Validates zip code.
 * @param {String} value
 * @returns {Boolean}
 */
export function validateZipCode(value) {
  return !!value.length && validationHelper.validateZipCode({ fieldValue: value });
}

/**
 * Validate VIN.
 * @param {String} value
 * @returns {Boolean}
 */
export function validateVin(value) {
  return validation.validateVIN(value);
}

export function validateConditionsQuestionsResponses(verboseResponse) {
  return (
    verboseResponse &&
    Object.keys(verboseResponse).every(id => !isEmpty(verboseResponse[id].answers) || verboseResponse[id].isOptional)
  );
}

export const VALIDATION_MAP = {
  [LOCATION_AND_STYLE]: [
    {
      fieldName: MAKE_INPUT,
      validators: [
        {
          test: isValueSet,
          message: ERROR_MAP.get(MAKE_INPUT),
        },
      ],
    },
    {
      fieldName: MODEL_INPUT,
      validators: [
        {
          test: isValueSet,
          message: ERROR_MAP.get(MODEL_INPUT),
        },
      ],
    },
    {
      fieldName: YEAR_INPUT,
      validators: [
        {
          test: isValueSet,
          message: ERROR_MAP.get(YEAR_INPUT),
        },
      ],
    },
    {
      fieldName: STYLE_INPUT,
      validators: [
        {
          test: isValueSet,
          message: ERROR_MAP.get(STYLE_INPUT),
        },
      ],
    },
    {
      fieldName: ZIP_CODE_INPUT,
      validators: [
        {
          test: validateZipCode,
          message: ERROR_MAP.get(ZIP_CODE_INPUT),
        },
      ],
    },
  ],
  [COLORS_AND_OPTIONS]: [
    {
      fieldName: TRANSMISSION_TYPE_INPUT,
      validators: [
        {
          test: isValueSet,
          message: ERROR_MAP.get(TRANSMISSION_TYPE_INPUT),
        },
      ],
    },
    {
      fieldName: EXTERIOR_COLOR,
      validators: [
        {
          test: isValueSet,
          message: ERROR_MAP.get(EXTERIOR_COLOR),
        },
      ],
    },
  ],
  [CONDITION_AND_MILEAGE]: [
    {
      fieldName: MILEAGE_INPUT,
      validators: [
        {
          test: validateMileage,
          message: ERROR_MAP.get(MILEAGE_INPUT),
        },
        {
          test: validateMaxMileage,
          message: ERROR_MAP.get(MILEAGE_INPUT_MAXIMUM_VALUE),
        },
        {
          test: validateMileageWithModelYear,
          message: ERROR_MAP.get(MILEAGE_INPUT_MINIMUM_VALUE_BY_MODEL_YEAR),
        },
      ],
    },
    {
      fieldName: CONDITION_INPUT,
      validators: [
        {
          test: isConditionValid,
          message: ERROR_MAP.get(CONDITION_INPUT),
        },
        {
          test: isConditionNotDamaged,
          message: DAMAGED_CONDITION_ERROR,
        },
      ],
    },
  ],
  [PARTNER_OFFER_CONDITIONS]: [
    {
      fieldName: CONDITIONS_QUESTIONS_SECTION_INPUT,
      validators: [
        {
          test: validateConditionsQuestionsResponses,
          message: ERROR_MAP.get(CONDITIONS_QUESTIONS_SECTION_INPUT),
        },
      ],
    },
  ],
};

/**
 * Is field value is valid
 * @param {String} stepId
 * @param {String} field name
 * @param {String} value
 * @param {Object} additionalParameters (optional) - additional parameters that is needed to validate (i.e. if we need vehicle year)
 * @returns {Boolean}
 */
export function isFieldValueValid(stepId, field, value, additionalParameters) {
  const fieldItem = find(VALIDATION_MAP[stepId], ({ fieldName }) => fieldName === field);
  return reduce(
    fieldItem && fieldItem.validators,
    (result, { test }) => result && test(value, additionalParameters),
    true
  );
}

/**
 * Gets an error message or undefined if field value is valid
 * @param {String} stepId
 * @param {String} field name
 * @param {String} value
 * @param {Object} additionalParameters (optional) - additional parameters that is needed to validate (i.e. if we need vehicle year)
 * @returns {String}
 */
export function getValidationErrorMessage(stepId, field, value, additionalParameters) {
  const fieldItem = find(VALIDATION_MAP[stepId], ({ fieldName }) => fieldName === field);
  return reduce(
    fieldItem && fieldItem.validators,
    (errorMessage, { test, message }) =>
      (!errorMessage && !test(value, additionalParameters) && message) || errorMessage,
    undefined
  );
}

// this map is used to define an element (by the 'selector') to scroll to, when validation of the 'input' fails.
export const SCROLL_MAP = [
  { input: COLOR_INPUT, selector: '.colors-and-options-step' },
  { input: MILEAGE_INPUT, selector: '.condition-mileage', options: { block: 'end' } },
  { input: CONDITION_INPUT, selector: '.condition-and-mileage-step' },
  { input: EXTERIOR_COLOR, selector: '.colors-section.exteriorColor' },
  /**
   * Commented out EPO_VIN_INPUT_CONTAINER scroll map due to product feedback for EVAL-1848
   *  - We don't want to scroll to the container on error)
   * Keeping it commented out just in case we want to bring it back in the future
   */
  // { input: EPO_VIN_INPUT_CONTAINER, selector: '.epo-license-plate-and-vin-tab-content' },
  { input: CONDITIONS_QUESTIONS_SECTION_INPUT, selector: `[id^="${CONDITIONS_QUESTIONS_SECTION_INPUT}-error"]` },
];
