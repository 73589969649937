import React from 'react';
import { omit } from 'lodash';
import PropTypes from 'prop-types';
import { Select as CommonSelect } from 'site-modules/shared/components/select/select';
import { StyledSelectPropTypes } from 'site-modules/shared/components/styled-select/styled-select';

const OMIT_PROPS = ['pubStates', 'includeAllMakes', 'loadModel', 'setModelValue'];

export function SelectComponent({ selectComponent: Select, ...restProps }) {
  const props = omit(restProps, OMIT_PROPS);

  return <Select {...props} />;
}

SelectComponent.propTypes = {
  ...StyledSelectPropTypes,
  selectComponent: PropTypes.elementType,
};

SelectComponent.defaultProps = {
  selectComponent: CommonSelect,
};
