import { connectToModel, bindToPath } from 'client/data/luckdragon/redux/react-binding';
import { VehicleModel } from 'client/data/models/vehicle';
import { SelectComponent } from 'site-modules/shared/components/vehicle-dropdowns/select-component';
import { StyledSelect } from 'site-modules/shared/components/styled-select/styled-select';
import { filterPubStates } from 'site-modules/shared/utils/publication-states';
import { DEFAULT_CLASSNAMES, ALL_MAKES_OPTION } from './constants';

const stateToPropsConfig = {
  options: bindToPath('makesList', VehicleModel, (makeData, { pubStates, includeAllMakes, valueKey, labelKey }) =>
    includeAllMakes
      ? [
          { [valueKey]: ALL_MAKES_OPTION.slug, [labelKey]: ALL_MAKES_OPTION.name },
          ...filterPubStates(makeData, pubStates),
        ]
      : filterPubStates(makeData, pubStates)
  ),
};

export const MakeDropdown = connectToModel(SelectComponent, stateToPropsConfig);

const DEFAULT_PROPS = {
  labelText: 'Make',
  isLabelHidden: true,
  name: 'select-make',
  toggle: 'Select Make',
  valueKey: 'slug',
  labelKey: 'name',
  includeAllMakes: false,
};

export const StyledMakeDropdown = connectToModel(StyledSelect, stateToPropsConfig);

StyledMakeDropdown.defaultProps = {
  ...DEFAULT_PROPS,
};

MakeDropdown.defaultProps = {
  ...DEFAULT_PROPS,
  className: `js-makes-select ${DEFAULT_CLASSNAMES}`,
};
